@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    @apply font-work_sans;
  }
}

@layer components {
  .custom-container-small {
    @apply px-6 md:px-10 lg:px-16 mx-auto max-w-[1220px];
  }

	.custom-container-medium {
    @apply px-6 md:px-10 lg:px-16 mx-auto max-w-[1440px];
  }
}


.custom-scrollbar::-webkit-scrollbar-tracks
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}

.custom-scrollbar::-webkit-scrollbar
{
	width: 12px;
  border-radius: 10px;
	background-color: #d6d6d6;
}

.custom-scrollbar::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #555;
}
